
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import imageCompression from 'browser-image-compression'

  const defaultProduct = {
    outSpaceFullCode: '',
    outSpaceName: '',
    stockId: '',
    productId: '',
    productNo: '',
    productProperty: 0,
    productName: '',
    standard: '',
    unit: '',
    actPrice: '',
    stockNum: '',
    receiveNum: 1,
    inSpaceName: '',
    inSpaceFullCode: ''
  }

  const productPropertyList = [
    { label: "易耗品", value: 1 },
    { label: "低值品", value: 2 },
    { label: "固定资产", value: 3 }
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i => i.value), j => j.label)

  const receiveTypeList = [
    { label: "维修领用", value: 1 },
    { label: "保洁领用", value: 2 },
    { label: "办公使用", value: 3 },
    { label: "客户维系", value: 4 }
  ]
  const receiveTypeMap = _.mapValues(_.keyBy(receiveTypeList, i => i.value), j => j.label)

  export default {
    name: 'AssetsTake',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo'
      ]),
      filterInSpaceList() {
        return this.inSpaceList.filter(item => item.spaceName.includes(this.inSpaceVal))
      }
    },
    data() {
      return {
        applicationPerson: '',
        receiveType: '',
        receiveReason: '',
        projectId: '',
        projectName: '',
        inSpaceCode: '',
        inSpaceName: '',
        productList: [
          { ...defaultProduct }
        ],
        outSpaceCode: '',
        outSpaceName: '',
        outKeeper: '',
        outSpaceProjectId: '',
        remark: '',
        attachment: '',

        receiveTypeShow: false,
        receiveTypeText: '',
        receiveTypeList,

        inSpaceListShow: false,
        inSpaceVal: '',
        inSpaceList: [],
        currentProductIndex: -1,
        productPropertyListShow: false,
        productPropertyList,
        productPropertyMap,
        productListShow: false,
        productListGets: [],
        productListSearchKeyword: '',
        productListSearchPageNum: 1,
        productListSearchPageSize: 10,
        productListSearchTotal: 0,
        stockInImgList: []
      }
    },
    mounted() {
      this.applicationPerson = this.userInfo.userName
      this.projectId = this.projectInfo.dcProjectId
      this.projectName = this.projectInfo.projectName
    },
    methods: {
      onReceiveTypeSelect(item) {
        this.receiveType = item.value
        this.receiveTypeText = item.label
        this.receiveTypeShow = false
      },
      onInSpaceListShow(index) {
        let { dcProjectId } = this.projectInfo
        assetsApi.getTakeSpaceList(dcProjectId).then(res => {
          if (res.data.data) {
            this.inSpaceList = res.data.data
          }
        })
        this.inSpaceListShow = true
        this.currentProductIndex = index
        this.$nextTick(() => {
          let value = this.productList[index].inSpaceFullCode
          let selectIndex = this.inSpaceList.findIndex(i => i.spaceFullCode === value)
          this.$refs.inSpaceList.setIndexes([selectIndex])
        })
      },
      onInSpaceListSelect(item) {
        if (!item) {
          this.$toast('无投放位置')
          return
        }
        this.productList[this.currentProductIndex].inSpaceFullCode = item.spaceFullCode
        this.productList[this.currentProductIndex].inSpaceName = item.spaceFullName
        this.inSpaceListShow = false
      },
      onProductPropertyShow(index) {
        this.productPropertyListShow = true
        this.currentProductIndex = index
        this.$nextTick(() => {
          let value = this.productList[index].productProperty
          let selectIndex = this.productPropertyList.findIndex(i => i.value === value)
          this.$refs.productPropertyList.setIndexes([selectIndex])
        })
      },
      onProductPropertySelect(item) {
        if (this.productList[this.currentProductIndex].productProperty !== item.value) {
          let product = {
            productProperty: item.value
          }
          this.onProductListSelect(product)
        }
        this.productPropertyListShow = false
      },
      onProductListShow(index) {
        this.currentProductIndex = index
        let productProperty = this.productList[this.currentProductIndex].productProperty
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        this.productListShow = true
        this.onProductListSearch()
      },
      onProductListSearch() {
        let productProperty = this.productList[this.currentProductIndex].productProperty
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        let params = {
          productProperty: productProperty,
          productNameProductNo: this.productListSearchKeyword,
          spaceType: '仓库',
          pageNum: this.productListSearchPageNum,
          pageSize: this.productListSearchPageSize,
          projectId: this.projectId
        }
        assetsApi.getStockProductList(params).then(res => {
          this.productListGets = res.data.data
          this.productListSearchTotal = res.data.count
        })
      },
      onProductListSearchPage() {
        this.onProductListSearch()
      },
      onProductListSelect(product) {
        let item = this.productList[this.currentProductIndex]
        item.stockId = product.id
        item.productNo = product.productNo || ''
        item.productId = product.productId
        item.productName = product.productName || ''
        item.standard = product.standards || ''
        item.unit = product.unit || ''
        item.actPrice = product.actPrice || ''
        item.productProperty = product.productProperty
        item.outSpaceFullCode = product.spaceFullCode || ''
        item.outSpaceName = product.spaceName || ''
        item.stockNum = product.count || 0
        item.receiveNum = product.count && product.count > 0 ? 1 : 0
        this.productListShow = false
      },
      onAddItem() {
        this.productList.push({
          ...defaultProduct
        })
      },
      afterFileRead(item) {
        let up = this.$toast.loading({ forbidClick: true, duration: 0 })
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file => {
          assetsApi.fileUpload(item).then(res => {
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1
              item.url = cb.data.url
              item.fileUrl = cb.data.url
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed'
              item.message = '上传失败'
            }
          }).catch(e => {
            this.$toast.clear()
            item.status = 'failed'
            item.message = '上传失败'
          })
        }).catch(() => {
          this.$toast.clear()
          item.status = 'failed'
          item.message = '上传失败'
        })
      },
      onDelItem(index) {
        this.$dialog.confirm({
          message: '是否确认删除这条记录？'
        }).then(() => {
          this.productList.splice(index, 1)
        }).catch(() => {
          // on cancel
        })
      },
      onSubmit() {
        if (!this.receiveType) {
          this.$toast('请选择领用类型')
          return
        }
        if (!this.receiveReason) {
          this.$toast('请填写申领原因')
          return
        }
        if (this.productList.length === 0) {
          this.$toast('请添加物资')
          return
        } else if (this.productList.length) {
          for (let i = 0; i < this.productList.length; i++) {
            let item = this.productList[i]
            if (!item.productProperty) {
              this.$toast(`请选择第${i + 1}条明细的资产属性`)
              return
            }
            if (!item.stockId) {
              this.$toast(`请选择第${i + 1}条明细的资产名称`)
              return
            }
            if (!item.receiveNum) {
              this.$toast(`请选择第${i + 1}条明细的数量`)
              return
            }
            if ((item.productProperty == 2 || item.productProperty == 3) && !item.inSpaceFullCode) {
              this.$toast(`请选择第${i + 1}条明细的投放位置`)
              return
            }
          }
        }
        this.$dialog.confirm({
          message: '是否确认提交？'
        }).then(() => {
          assetsApi.addTake({
            projectId: this.projectId,
            projectName: this.projectName,
            applicationPerson: this.applicationPerson,
            receiveType: this.receiveType,
            receiveReason: this.receiveReason,
            takeDetailVos: this.productList,
            fileList: this.stockInImgList.map(i => { return { fileUrl: i.fileUrl } })
          }).then(res => {
            this.$router.replace({ name: 'AssetsTakeSuccess' })
          })
        }).catch(() => {
          // on cancel
        })
      }
    }
  }
